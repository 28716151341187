import View3D, { ARButton, AROverlay } from "@egjs/view3d";
import "@egjs/view3d/css/view3d-bundle.min.css";
import 'regenerator-runtime/runtime'

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let model = urlParams.get('model');

const models = {
    '1':"vending-1.glb",
    '2':"vending-machine-light.glb",
    '3':"vending-machine-light.gltf",
}

if(!models[model])
    model = '1';

const view3d = new View3D("#el", {
    src: `models/${models[model]}`,
    iosSrc: "/models/vending-machine.usdz",
   /// src: "https://git.smrt1.app/experiments/augmented-reality/-/raw/master/assets/models/smrt1-vending-machine.glb?inline=false",
    envmap: "https://naver.github.io//egjs-view3d/texture/artist_workshop_1k.hdr"
 //   src: "https://naver.github.io/egjs-view3d/model/draco/alarm.glb",
 //   envmap: "https://naver.github.io/egjs-view3d/texture/artist_workshop_1k.hdr",
});



view3d.loadPlugins( new ARButton() );
view3d.loadPlugins( new AROverlay() );

document
.querySelector("#enter-ar")
.addEventListener("click",  () => {

    try{ 
       view3d.ar.enter();
    }catch(err){  console.log('view3d.ar.enter() failed with error', err) }
});





